
import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { mapGetters } from "vuex";
import {Select, Option} from "element-ui";

const ConceptFinancialProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [translations],
})
export default class ConceptFinancial extends ConceptFinancialProps {
  translate!: Function;
  public getLocale: any;
  currencyFormats: Array<any> = [
    {
      value: 'amount_currency_symbol',
      text: "Amount, Currency Symbol",
    },
    {
      value: 'amount_currency_code',
      text: "Amount, Currency Code",
    },
    {
      value: 'currency_code_amount',
      text: "Currency Code, Amount",
    },
    {
      value: 'currency_symbol_amount',
      text: "Currency Symbol, Amount",
    },
  ]
// up, down, half_up, none
  roundingModes: Array<any> = [
    {
      value: 'none',
      text: "None",
    },
    {
      value: 'up',
      text: "Up",
    },
    {
      value: 'half_up',
      text: "Half Up",
    },
    {
      value: 'down',
      text: "Down",
    },
  ];
  // 0/0.1/0.25/0.5/1
  roundingPrecision: Array<any> = [
    {
      value: '0',
      text: "0",
    },
    {
      value: '0.1',
      text: "0.1",
    },
    {
      value: '0.25',
      text: "0.25",
    },
    {
      value: '0.5',
      text: "0.5",
    },
    {
      value: '1',
      text: "1",
    }
  ]



  mounted(){
    console.log('data', this.data);
  }

}
